@use 'node_modules/@amzn/awsui-design-tokens/polaris.scss' as awsui;

.AQC_link {
    color: awsui.$color-text-link-default;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.AQC_Rounded_Button_Left {
    border-radius: 2px 0 0 2px;
}
.AQC_Rounded_Button_Right {
    border-radius: 0 2px 2px 0;
}
.AQC_button {
    width: 86px;
    height: 32px;
    border: 1px;
    box-sizing: border-box;
    font-family: "Amazon Ember";
    font-weight: bold;
    background-color: #EAEDED;
    color: black;
}
.AQC_button_Selected {
    background-color: #232F3E;
    color: white;
}

.AQC_button_Not_Selected {
    background-color: #EAEDED;
    color: black;
}

.aligin-center {
    display:'flex';
    align-items:'center';
    justify-content:'center'
}

.OverviewBg {
    // background-image: url('src/Assets/images/bg2.png');
    // background-repeat: no-repeat;
    // background-size:cover;
    // background-color: #FF9900;
}

.OverviewText{
    font-size: 12pt;
}

.ComingSoon {
    background-image: url('src/Assets/images/coming-soon-gif.gif');
    background-repeat: no-repeat;
    background-size:cover;
    // background-color: #FF9900;
}